@import 'styles/settings.scss';

.c-crop-image {
  &__preview {
    max-height: 650px !important;
  }

  &__canvas {
    object-fit: contain;
    width: 150px;
    height: 150px;
    display: none;
  }
}
