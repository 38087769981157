@import 'styles/settings.scss';

.c-button {
  display: flex;
  align-items: center;

  padding: 7px $spacing-xs;

  border-radius: $radius-xs;
  position: relative;

  transition: background-color $transition-1;
  cursor: pointer;

  &__icon {
    display: inline-flex;

    svg {
      fill: $color-white;
      max-width: 16px;
      max-height: 15px;

      path {
        fill: $color-white;
        transition: fill $transition-1;
      }

      rect {
        fill: transparent;
      }
    }

    &--spacing-xs-plus {
      padding-left: $spacing-xs;
    }

    &--left {
      margin-right: $spacing-4xs;
    }

    &--right {
      margin-left: $spacing-4xs;
    }

    &--primary {
      path {
        fill: $color-primary;
      }
    }

    &--secondary {
      path {
        fill: $color-secondary;
      }
    }

    &--plain {
      path {
        fill: $color-gray;
      }
    }
  }

  &__text {
    color: $color-white;
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
    line-height: normal;
    transition: color $transition-1;
  }

  &--extra-small {
    padding: $spacing-3xs $spacing-4xl;

    .c-button__text {
      font-size: $fs-xxsmall;
    }
  }

  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--medium {
    padding: $spacing-xs 45px;
  }

  &--large {
    border-radius: $radius-s;
    width: 100%;
  }

  &--primary {
    background-color: $color-primary;
    border: 1px solid $color-primary;

    &:hover {
      background-color: darken($color-primary, 10%);
    }

    &.c-button--outlined {
      border: 1px solid $color-primary;

      .c-button__text {
        font-weight: $fw-bold-light;
        color: $color-dark-gray-new-2;
      }

      &:hover {
        background-color: darken($color-light-gray-new-1, 5%);
      }
    }
  }

  &--menu-item {
    &:hover {
      background-color: $color-primary-light !important;
      border-radius: 10px !important;
    }
  }
  &--secondary {
    background-color: $color-secondary;

    &:hover {
      background-color: darken($color-secondary, 10%);

      .c-button__text {
        font-weight: $fw-bold-light;
        color: $color-light-gray-new-1;
      }

      &:hover {
        path {
          fill: $color-light-gray-new-1;
        }
      }
    }
  }

  &--success {
    background-color: $color-success;
    font-weight: $fw-semi-bold;
    letter-spacing: $letter-spacing-s;

    &:hover {
      background-color: darken($color-success, 10%);
    }

    &.c-button--outlined {
      border: 1px solid $color-success;

      .c-button__text {
        font-weight: $fw-semi-bold;
        color: $color-dark-gray-new-2;
      }

      &:hover {
        background-color: $color-success;

        .c-button__text {
          color: $color-white;
        }
      }
    }
  }

  &--outlined {
    background-color: transparent;

    &:hover {
      background-color: $color-white;
      color: $color-dark-gray;
    }

    &.c-button--primary {
      &:hover {
        background-color: $color-primary;

        .c-button__text {
          color: $color-white;
        }

        .c-button__icon {
          path {
            fill: $color-white;
          }
        }
      }
    }
  }

  &--tertiary {
    background-color: $color-light-gray-new-1;

    .c-button__text {
      color: $color-dark-gray-new-2;
      font-weight: $fw-semi-bold;
    }

    &:hover {
      background-color: darken($color-light-gray-new-1, 10%);
    }

    &.c-button--outlined {
      background-color: $color-white;
      border: 1px solid $color-light-gray-new-3;

      .c-button__text {
        font-weight: $fw-bold-light;
        color: $color-light-gray-new-3;
      }

      &:hover {
        background-color: $color-white;
      }
    }

    svg {
      path {
        fill: $color-dark-gray-new-2;
      }
    }
  }

  &--plain {
    background-color: $color-white;
    border: 1px solid $color-gray;

    display: flex;
    align-items: center;

    transition: box-shadow $transition-1;

    svg {
      height: 18px;
      width: 18px;
    }

    &:hover {
      box-shadow: $box-shadow-4;
      cursor: pointer;
    }
  }

  &--text {
    background-color: transparent;
    border-color: transparent;
    color: $color-dark-gray-new-2;

    .c-button__text {
      color: $color-dark-gray-new-2;
      font-weight: $fw-medium;
    }

    .c-button__icon {
      path {
        fill: $color-primary;
      }
    }

    &.c-button--active {
      background-color: $color-light-gray-new-5;
    }

    &:hover {
      background-color: $color-light-gray-new-1;
    }

    &:disabled {
      background-color: transparent !important;
      border-color: transparent !important;

      .c-button__text {
        color: $color-light-gray-new-2 !important;
      }
    }
  }

  &:disabled {
    .c-button__text {
      color: $color-white;
    }

    pointer-events: none;
    background-color: $color-light-gray-new-2;
    border-color: $color-light-gray-new-2;
  }

  &--center {
    justify-content: center;
  }

  &--flex-start {
    justify-content: flex-start;
  }
}
